/**
 * Copyright 2020-Present Han Lee <hanlee.dev@gmail.com>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const HWPTAG_BEGIN: number = 0x10

export enum DocInfoTagID {
  HWPTAG_DOCUMENT_PROPERTIES = HWPTAG_BEGIN,
  HWPTAG_ID_MAPPINGS = HWPTAG_BEGIN + 1,
  HWPTAG_BIN_DATA = HWPTAG_BEGIN + 2,
  HWPTAG_FACE_NAME = HWPTAG_BEGIN + 3,
  HWPTAG_BORDER_FILL = HWPTAG_BEGIN + 4,
  HWPTAG_CHAR_SHAPE = HWPTAG_BEGIN + 5,
  HWPTAG_TAB_DEF = HWPTAG_BEGIN + 6,
  HWPTAG_NUMBERING = HWPTAG_BEGIN + 7,
  HWPTAG_BULLET = HWPTAG_BEGIN + 8,
  HWPTAG_PARA_SHAPE = HWPTAG_BEGIN + 9,
  HWPTAG_STYLE = HWPTAG_BEGIN + 10,
  HWPTAG_DOC_DATA = HWPTAG_BEGIN + 11,
  HWPTAG_DISTRIBUTE_DOC_DATA = HWPTAG_BEGIN + 12,
  RESERVED = HWPTAG_BEGIN + 13,
  HWPTAG_COMPATIBLE_DOCUMENT = HWPTAG_BEGIN + 14,
  HWPTAG_LAYOUT_COMPATIBILITY = HWPTAG_BEGIN + 15,
  HWPTAG_TRACKCHANGE = HWPTAG_BEGIN + 16,
  HWPTAG_MEMO_SHAPE = HWPTAG_BEGIN + 76,
  HWPTAG_FORBIDDEN_CHAR = HWPTAG_BEGIN + 78,
  HWPTAG_TRACK_CHANGE = HWPTAG_BEGIN + 80,
  HWPTAG_TRACK_CHANGE_AUTHOR = HWPTAG_BEGIN + 81,
}

export enum SectionTagID {
  HWPTAG_PARA_HEADER = HWPTAG_BEGIN + 50,
  HWPTAG_PARA_TEXT = HWPTAG_BEGIN + 51,
  HWPTAG_PARA_CHAR_SHAPE = HWPTAG_BEGIN + 52,
  HWPTAG_PARA_LINE_SEG = HWPTAG_BEGIN + 53,
  HWPTAG_PARA_RANGE_TAG = HWPTAG_BEGIN + 54,
  HWPTAG_CTRL_HEADER = HWPTAG_BEGIN + 55,
  HWPTAG_LIST_HEADER = HWPTAG_BEGIN + 56,
  HWPTAG_PAGE_DEF = HWPTAG_BEGIN + 57,
  HWPTAG_FOOTNOTE_SHAPE = HWPTAG_BEGIN + 58,
  HWPTAG_PAGE_BORDER_FILL = HWPTAG_BEGIN + 59,
  HWPTAG_SHAPE_COMPONENT = HWPTAG_BEGIN + 60,
  HWPTAG_TABLE = HWPTAG_BEGIN + 61,
  HWPTAG_SHAPE_COMPONENT_LINE = HWPTAG_BEGIN + 62,
  HWPTAG_SHAPE_COMPONENT_RECTANGLE = HWPTAG_BEGIN + 63,
  HWPTAG_SHAPE_COMPONENT_ELLIPSE = HWPTAG_BEGIN + 64,
  HWPTAG_SHAPE_COMPONENT_ARC = HWPTAG_BEGIN + 65,
  HWPTAG_SHAPE_COMPONENT_POLYGON = HWPTAG_BEGIN + 66,
  HWPTAG_SHAPE_COMPONENT_CURVE = HWPTAG_BEGIN + 67,
  HWPTAG_SHAPE_COMPONENT_OLE = HWPTAG_BEGIN + 68,
  HWPTAG_SHAPE_COMPONENT_PICTURE = HWPTAG_BEGIN + 69,
  HWPTAG_SHAPE_COMPONENT_CONTAINER = HWPTAG_BEGIN + 70,
  HWPTAG_CTRL_DATA = HWPTAG_BEGIN + 71,
  HWPTAG_EQEDIT = HWPTAG_BEGIN + 72,
  RESERVED = HWPTAG_BEGIN + 73,
  HWPTAG_SHAPE_COMPONENT_TEXTART = HWPTAG_BEGIN + 74,
  HWPTAG_FORM_OBJECT = HWPTAG_BEGIN + 75,
  HWPTAG_MEMO_SHAPE = HWPTAG_BEGIN + 76,
  HWPTAG_MEMO_LIST = HWPTAG_BEGIN + 77,
  HWPTAG_CHART_DATA = HWPTAG_BEGIN + 79,
  HWPTAG_VIDEO_DATA = HWPTAG_BEGIN + 82,
  HWPTAG_SHAPE_COMPONENT_UNKNOWN = HWPTAG_BEGIN + 99,
}
