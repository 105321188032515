// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import HelloWorld from "./components/HelloWorld";
//import HwpViewerIndexPage from "./HwpViewerIndexPage";

//Rails.start()
//Turbolinks.start();
ActiveStorage.start()

// // Add Turbolinks to the global namespace:
// window.Turbolinks = Turbolinks;

// // Remove previous event handlers and add new ones:
// ReactRailsUJS.detectEvents();

// // (Optional) Clean up global namespace:
// delete window.Turbolinks;

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// // Remove previous event handlers and add new ones:
ReactRailsUJS.detectEvents();
