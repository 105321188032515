import HWPViewer from '../hwp.js/viewer'
import React, { useState, useCallback, useEffect, useRef } from 'react'
import Loader from 'react-loader-spinner'

import Page from '../packs/components/Page'

function HwpViewerIndexPage(props) {
  const viewerRef = useRef<HTMLDivElement | null>(null)

  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  //const [postUrl, setPostUrl] = useState("")

  console.log(props);

  const loadSampleHWP = useCallback(() => {
    setIsLoading(true)
    setHasError(false)

    //fetch('files/noori.hwp')
    fetch(props.post_url)
      .then(res => res.arrayBuffer())
      .then(res => {
        const array = new Uint8Array(res)
        setIsLoading(false)
        new HWPViewer(viewerRef.current, array, { type: 'array' })
      })
      .catch(() => {
        setIsLoading(false)
        setHasError(true)
      })
  }, [])

  useEffect(() => {
    loadSampleHWP()
  }, [])

  return (
    <Page title="hwp.js">
      <main className="index">
        {/* <section className="main">
          <img src="images/logo.svg" />
          <p>Open source hwp viewer and parser library powered by web technology</p>
          <a type="button" className="btn btn-default" href="https://github.com/hahnlee/hwp.js">GitHub</a>
          <a type="button" className="btn btn-default" href="https://chrome.google.com/webstore/detail/hwpjs/hgalnnpknkjdccmcljadkckcdibiglei">
            Chrome extension
          </a>
          <a type="button" className="btn btn-default" href="https://github.com/hahnlee/hwp.js/issues/7">공지사항</a>
          <a type="button" className="btn btn-default" href="demo">Demo</a>
          <pre className="code">
            <code>
              npm install hwp.js
              <br />
              yarn add hwp.js
            </code>
          </pre>
        </section> */}
        <div className="hwp_container" ref={viewerRef}>
          {
            isLoading && (
              <div className="notice">
                <Loader
                  type="Oval"
                  color="#00BFFF"
                  height={30}
                  width={30}
                />
              </div>
            )
          }
          {
            hasError && (
              <div className="notice">
                <br></br>
                <h3>에러가 발생했습니다 :( </h3>
                <br></br>
                <button className="btn btn-default" onClick={loadSampleHWP}>다시시도 하기</button>
                <br></br>
              </div>
            )
          }
        </div>
      </main>
    </Page>
  )
}

export default HwpViewerIndexPage
